import "./style.scss";

import { Layout } from "antd";
import LogoTDM from "../../../assets/images/logo/tdm-logo.png";
import { useContext } from "react";
import { StoreContext } from "../../context/provider";
import { LeftOutlined } from "@ant-design/icons";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

export default function HeaderComponent() {
  const { Header } = Layout;

  const storeContext = useContext(StoreContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { truck_plate_number } = useParams();

  return (
    storeContext?.storeDetail.name &&
    storeContext?.storeDetail.number && (
      <Header style={{ position: "sticky", top: 0, zIndex: 1, display: "flex" }}>
        <div className="top-left-items">
          {truck_plate_number && (
            <LeftOutlined
              style={{
                fontSize: "24px",
                color: "#006D2E",
              }}
              onClick={() => {
                const path = window.location.pathname.split("/").slice(0, -1).join("/");
                navigate(`${path}?${searchParams.toString()}`);
              }}
            />
          )}
          <div className="store-info">
            <span className="title">สวัสดี, {storeContext ? storeContext.storeDetail.name : ""} </span>
            <span className="sub-title">
              รหัสร้านค้า
              <span className="sub-title content">{storeContext ? storeContext.storeDetail.number : ""}</span>
            </span>
          </div>
        </div>
        <div className="top-right-items">
          <img src={LogoTDM} alt="logo" height={35} />
        </div>
      </Header>
    )
  );
}
