import "./style.scss";

import { Layout } from "antd";
import { StoreOwner } from "./Slice";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import SummaryComponent from "./components/SummaryComponent";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { getStoresTracking } from "./services/store-tracking";
import { StoreContext } from "../../shared/context/provider";
import { useContext, useEffect } from "react";
import LoadingComponent from "./components/LoadingComponent";

export default function StoreTracking() {
  const { tracking_number } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const storeContext = useContext(StoreContext);

  const { data, error, isLoading, isFetching } = useQuery<StoreOwner, AxiosError>({
    queryKey: ["storeTracking"],
    queryFn: () => getStoresTracking(tracking_number as string, searchParams.get("company") as string),
    refetchInterval: 2 * (1000 * 60),
  });

  if (error && error.response) {
    const httpStatus = error.response.status;
    if (httpStatus === 404) {
      navigate("/error?status=404");
    } else {
      navigate("/error?status=500");
    }
  }

  useEffect(() => {
    if (data) {
      storeContext?.setStoreDetail({
        name: data.store_name,
        number: data.store_number,
      });
    }
  }, [data]);

  return (
    <>
      {isLoading || isFetching ? (
        <LoadingComponent />
      ) : (
        <Layout className="wrap-content content-tracking">
          <h3 className="shipping-title">รายการรับสินค้าวันนี้</h3>
          {data && <SummaryComponent data={data} />}
        </Layout>
      )}
    </>
  );
}
