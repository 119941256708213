import "../src/style/global.scss";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import { Layout } from "antd";
import { Outlet } from "react-router";
import Redirect from "./feature/Redirect/Redirect";
import StoreTracking from "./feature/StoreTracking/StoreTracking";
import LineRegister from "./feature/LineRegister/LineRegister";
import ErrorPage from "./feature/ErrorPage/ErrorPage";
import HeaderComponent from "./shared/components/HeaderComponent/HeaderComponent";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import DetailComponent from "./feature/StoreTracking/components/DetailComponent";
import { useState } from "react";
import { StoreContext } from "./shared/context/provider";

export interface StoreDetail {
  name: string;
  number: string;
  displayBack?: boolean;
}

const HeaderLayout = () => (
  <>
    <HeaderComponent />
    <Outlet />
  </>
);

function App() {
  const queryClient = new QueryClient();
  const [storeDetail, setStoreDetail] = useState<StoreDetail>({
    name: "",
    number: "",
    displayBack: false,
  });

  return (
    <StoreContext.Provider value={{ storeDetail, setStoreDetail }}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Layout className="management-layout">
            <Layout>
              <Routes>
                <Route element={<HeaderLayout />}>
                  <Route path="/t/:tracking_number" element={<StoreTracking />} />
                  <Route path="/t/:tracking_number/:truck_plate_number" element={<DetailComponent />} />
                </Route>
                <Route path="/" element={<Redirect />} />
                <Route path="/line/register" element={<LineRegister />} />
                <Route path="error" element={<ErrorPage />} />
              </Routes>
            </Layout>
          </Layout>
        </BrowserRouter>
      </QueryClientProvider>
    </StoreContext.Provider>
  );
}

export default App;
