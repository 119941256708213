import "./style.scss";
import { LoadingOutlined } from "@ant-design/icons";
import { Layout, Spin } from "antd";

function LoadingComponent() {
  return (
    <Spin
      className="loading-component"
      indicator={<LoadingOutlined spin />}
      size="large"
      tip="กำลังโหลดรายการ"
    >
      <Layout className="wrap-content content-tracking"></Layout>
    </Spin>
  );
}

export default LoadingComponent;
